@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.react-datetime-picker__wrapper {
    background-color: white;
    border-radius: 6px;
    padding: 6px;
}

abbr:where([title]) {
    text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday {
    color: #777;
}

.react-calendar__navigation {
    margin-bottom: 2px !important;
    border-bottom: 1px solid #ccc;
}

.react-calendar__navigation__label__labelText {
    display: block;
    padding-top: 4px !important;
}

.react-calendar__navigation__label__labelText,
.react-calendar__navigation button {
    background-color: unset !important;
    color: #333;
    font-size: 14px;
}

.react-datetime-picker__calendar .react-calendar {
    border-radius: 6px;
    margin-top: 6px;
}

.react-calendar__navigation__arrow {
    font-size: 22px !important;
}

.notepad-content {
    font-family: "Proxima Nova", sans-serif;
}
.notepad-content h1 {
    color: #999999;
}
.notepad-content h1, h2, h3, h4, h5, h6 {
    font-size: revert;
    font-weight: revert; 
}
.notepad-content ul.bullet {
    list-style-type: disc;
    margin-left: 1.5em;
}
.notepad-content .color\:black {
  color: #3A3B3D;
}
.notepad-content .color\:red {
  color: #BB433D;
}
.notepad-content .color\:green {
  color: #2E8747;
}
.notepad-content .color\:blue {
  color: #025ACF;
}
.notepad-content .color\:yellow {
  color: #7E4FDE;
}
.notepad-content .color\:orange {
  color: #F2C40D;
}

.notepad-content.expanded-true .text-added {
    background-color: #e6ffed;
}
.notepad-content.expanded-true .text-removed {
    text-decoration: line-through;
}
